<template>
    <div class="columns" style="width: 100%; padding: 0px !important;
    overflow: scroll;">
      <div
        v-if="!isLoading"
        class="column"
        style="width: 100%; padding: 0px !important"
      >
        <input type="file" id="csvFile" accept=".csv" style="display: none;">
        <p style="margin-bottom: 2rem; max-width: 75%; font-weight: bold; font-size: .8rem; color: #3e5881;">
          You can download a CSV template to import your contacts. Please
          click "Export" to download the template. Once you
          have filled out the template, you can import it by clicking "Import".
        </p>
        <div
          class="subheading"
          style="
            padding: 0px 0px 7px 0px;
            margin-bottom: 1.5rem;
            border-bottom: solid 1px lightgray;
            position: relative;
          "
        >
          <b>Participant Information</b>
          <div class="show-deleted">
            <button
              v-if="hasDeletedContacts"
              @click="showDeletedContacts = !showDeletedContacts"
              type="button"
            >
              {{ showDeletedContacts ? "Hide" : "Show" }} Deleted Participants
            </button>
            <button
              @click="uploadParticipantCSV()"
              class="button is-secondary is-small"
              type="button"
              style="margin-left: 1rem"
            >
              Import
            </button>
            <button
              @click="downloadParticipantCSV()"
              class="button is-secondary is-small"
              type="button"
              style="margin-left: 1rem"
            >
              Export
            </button>
            <button
              @click="resetContactTable()"
              class="button is-warning is-small"
              type="button"
              style="margin-left: 1rem"
            >
              Reset Participants
            </button>

          </div>
          <br />
          Please review and correct the information as needed.
        </div>
        <div
          class="columns"
          style="width: 100%; padding: 0px 0px 7px 0px !important; gap: 0.5rem"
        >
          <div class="column">
            <div class="table">
              <DataTable
                :header-fields="participantFields"
                :tableData="filteredContacts"
                :is-loading="isLoading"
                :css="datatableCss"
                :limit="100"
                :listenOn="'participants-imported'"
                not-found-msg="No contacts found"
                @on-add-row="addContactRow"
              >
               <div slot="actions" slot-scope="props">
                  <a
                    v-if="props && props.rowData && props.rowData.isDeleted === 0"
                    class="button is-light"
                    @click="deleteRow(props.rowData)"
                    style="max-width: 50px"
                    >-</a
                  >
                  <a
                    v-if="props && props.rowData && props.rowData.isDeleted !== 0"
                    class="button is-light"
                    @click="unDeleted(props.rowData)"
                    style="max-width: 50px"
                    >+</a
                  >
                </div>
              </DataTable>
              <span v-for="error in errorsForm" :key="error.message">
                <p style="color: red">{{ error.message }}</p>
              </span>
              <br />
            </div>
          </div>
        </div>
        <div style="width: 100%; margin-top: 3rem; margin-bottom: 3rem;">
          <div style="display: flex; justify-content: flex-start; align-items: center;" :disabled="errors.length > 0">
          <input
            :disabled="errors.length > 0"
            type="checkbox"
            name="confirmed"
            v-model="hasReviewed"
            style="margin-right: 0.75rem"
          />
          <label for="confirmed" @click="!isValid ? null : hasReviewed = !hasReviewed" :disabled="!isValid"
            >I have reviewed the above information.</label
          >
        </div>
          <p :disabled="errors.length > 0" v-if="questionnaire.jsonReviewedBy && questionnaire.jsonReviewedBy.bankInformation" style="color: red;">
            {{ questionnaire.jsonReviewedBy.bankInformation }}
          </p>
        </div>
      </div>
      <div v-else>
        <Loading />
      </div>
    </div>
  </template>

<script>
import DataTable from '../../../templates/DataTable'
import Loading from '../../../Loading'
import { bus } from '@/main'
// import * as crypto from 'crypto'
import moment from 'moment'

function makeSafeForCSV (str) {
  if (str === null) return
  if (str.includes(',') || str.includes('"') || str.includes('\n')) {
    // If the string contains a double quote, escape it with another double quote
    str = str.replace(/"/g, '""')
    return '"' + str + '"'
  }
  return str
}

export default {
  name: 'BankInformationSlide',
  components: {
    DataTable,
    Loading
  },
  props: {
    questionnaire: {
      type: Object,
      required: true
    },
    participantsHash: {
      type: String,
      required: true
    },
    hash: {
      type: String,
      required: true
    }
  },
  watch: {
    questionnaire: {
      handler: function (val, oldVal) {
        this.GetBankInformation()
      },
      deep: true
    },
    hasReviewed: function (val, oldVal) {
      this.$emit('has-reviewed', val)
    },
    participantsHash: {
      handler (newVal, oldVal) {
        this.$emit('rendered', this)
        bus.$on('participants-imported', data => {
          this.questionnaire.participants = data
          this.$forceUpdate()
        })
        this.GetBankInformation()
        this.validateInput()
      }
    },
    hash: {
      handler: function (val, oldVal) {
        try {
          this.GetBankInformation()
          if (val !== oldVal) {
            this.hasReviewed = ''
            this.validateInput()
            if (this.isValid) {
              this.hasReviewed = this.questionnaire.jsonReviewedBy && this.questionnaire.jsonReviewedBy.bankInformation
            } else {
              this.hasReviewed = false
              this.$forceUpdate()
            }
          }
        } catch (_) {
          // console.log(error)
        }
      },
      deep: true
    }
  },
  data: function () {
    return {
      errorsForm: [],
      interval: null,
      isLoading: true,
      hasReviewed: false,
      isValid: false,
      formErrors: {},
      showDeletedContacts: false,
      participantFields: [
        {
          name: 'firstName',
          label: 'First Name',
          required: true,
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'lastName',
          label: 'Last Name',
          required: true,
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'previousTitle',
          label: 'Previous Title',
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'newTitle',
          label: 'New Title',
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'terminationDate',
          label: 'Termination Date',
          format: 'date',
          width: '15%',
          isEditable: true
        },
        {
          name: 'terminationReason',
          label: 'Termination Reason',
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'salary',
          label: 'Base Salary',
          required: true,
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'bonus',
          label: 'Bonus',
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'otherCompensation',
          label: 'Other Compensation',
          width: '11.1111111111%',
          isEditable: true
        },
        '__slot:actions'
      ],
      datatableCss: {
        table: 'table table-bordered table-hover table-striped table-center',
        th: 'header-item',
        thWrapper: 'th-wrapper',
        footer: 'footer'
      }
    }
  },
  mounted () {
    this.$emit('rendered', this)
    this.questionnaire.participants = this.questionnaire.participants.map(x => {
      x.hasfirstnameerror = false
      x.haslastnameerror = false
      x.hasterminationDateerror = false
      x.hasprevioustitleerror = false
      x.hassalaryerror = false

      return x
    })

    bus.$on('participants-imported', data => {
      this.questionnaire.participants = data.map(x => {
        x.hasfirstnameerror = false
        x.haslastnameerror = false
        x.hasterminationDateerror = false
        x.hasprevioustitleerror = false
        x.hassalaryerror = false

        return x
      })
      this.$forceUpdate()
    })
    this.GetBankInformation()

    this.validateInput()
    this.interval = setInterval(() => {
      this.validateInput()
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  beforeUpdate () {

  },
  computed: {
    hasDeletedContacts () {
      return this.questionnaire.participants &&
          this.questionnaire.participants.length > 0
        ? this.questionnaire.participants.filter((c) => c.isDeleted).length > 0
        : false
    },
    filteredContacts () {
      const parts = this.questionnaire.participants &&
          this.questionnaire.participants.length > 0
        ? this.showDeletedContacts
          ? this.questionnaire.participants
          : this.questionnaire.participants.filter((c) => parseInt(c.isDeleted) === 0)
        : []

      return parts
    }
  },
  methods: {
    excelSerialDateToDate (serialDate) {
      if (serialDate === null || serialDate === undefined || serialDate === '') {
        return null
      }

      if (typeof serialDate === 'string') {
        return moment(serialDate).format('MM/DD/YYYY')
      }

      const excelEpoch = new Date(1899, 11, 31) // December 31, 1899
      const excelEpochAsUnixTimestamp = excelEpoch.getTime()
      const missingLeapYearDay = (serialDate > 59) ? 1 : 0
      const daysToMilliseconds = 86400000
      const serialDateAdjusted = serialDate - missingLeapYearDay
      const date = new Date(excelEpochAsUnixTimestamp + (serialDateAdjusted * daysToMilliseconds))
      return moment(date).format('YYYY-MM-DD')
    },
    // crypto,
    validateParticipantRow (_, participant) {
      const SALARY_REQUIRED_FOR_UN_TERMINATED = {
        message: 'Salary is required if Termination Date is not provided',
        field: 'salary',
        color: 'red'
      }

      const FIRST_NAME_REQUIRED = {
        message: 'First Name is required',
        field: 'firstname',
        color: 'red'
      }

      const LAST_NAME_REQUIRED = {
        message: 'Last Name is required',
        field: 'lastname',
        color: 'red'
      }

      const INVALID_DATE_ERROR = {
        message: 'Invalid Date detected. Please use the format YYYY-MM-DD',
        field: 'terminationDate',
        color: 'red'
      }

      // const PREVIOUS_TITLE_REQUIRED = {
      //   message: 'Previous Title is required',
      //   field: 'previoustitle',
      //   color: 'red'
      // }

      //  reset the last errors on the participant before the next check
      participant.hasfirstNameerror = false
      participant.haslastNameerror = false
      participant.haspreviousTitleerror = false
      participant.hassalaryerror = false
      participant.hasterminationDateerror = false

      const isValid = participant.salary > 0 || (participant.salary === 0 && participant.terminationDate !== '')
      const errors = []
      if ((participant.firstName === '' || participant.firstName === undefined || participant.firstName === null)) {
        if ((_.errorsForm.find(x => x.message === FIRST_NAME_REQUIRED.message)) === undefined) {
          errors.push(FIRST_NAME_REQUIRED)
        }
        participant.hasfirstNameerror = true
      }

      if ((participant.lastName === '' || participant.lastName === undefined || participant.lastName === null)) {
        if ((_.errorsForm.find(x => x.message === LAST_NAME_REQUIRED.message)) === undefined) {
          errors.push(LAST_NAME_REQUIRED)
        }
        participant.haslastNameerror = true
      }

      if (participant.terminationDate === 'INVALID DATE') {
        if ((_.errorsForm.find(x => x.message === INVALID_DATE_ERROR.message)) === undefined) {
          errors.push(INVALID_DATE_ERROR)
        }
        participant.hasterminationDateerror = true
      }

      if (!isValid) {
        if (!participant.salary && !participant.terminationDate) {
          if ((_.errorsForm.find(x => x.message === SALARY_REQUIRED_FOR_UN_TERMINATED.message)) === undefined) {
            errors.push(SALARY_REQUIRED_FOR_UN_TERMINATED)
          }
          participant.hassalaryerror = true
        }
      }

      // this.errorsForm = errors

      // this.$emit('toggleLock', !(_.errorsForm.length === 0))

      return errors
    },
    downloadParticipantCSV () {
      let participants = this.questionnaire.participants
      this.downloadCSV(participants.map(x => {
        return {
          'participantId': parseInt(x.participantId) > 0 && !isNaN(parseInt(x.participantId)) ? x.participantId : 0,
          'firstName': makeSafeForCSV(x.firstName),
          'lastName': makeSafeForCSV(x.lastName),
          'previousTitle': makeSafeForCSV(x.previousTitle),
          'newTitle': makeSafeForCSV(x.newTitle),
          'terminationDate': x.terminationDate,
          'terminationReason': makeSafeForCSV(x.terminationReason),
          'salary': x.salary,
          'bonus': x.bonus,
          'otherCompensation': x.otherCompensation,
          'isDeleted': x.isDeleted
        }
      }), this.questionnaire.name.toLowerCase().replaceAll(' ', '-') + '-participants' + '.csv')
    },
    handleDateFormat (date) {
      if (date === null || date === undefined || date === '') {
        return null
      }

      if (typeof date === 'string') {
        // figure out of the date is in the format of MM/DD/YYYY, DD-MMM, YYYY-MM-DD, or YYYY-MM-DD HH:mm:ss
        if (date.includes('/')) {
          return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD')
        } else if (date.includes('-') && date.split('-').length === 2) {
          return moment(date, 'DD-MMM').format((moment()).year().toString() + '-MM-DD')
        } else if (date.includes('-')) {
          return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
        } else {
          return 'INVALID DATE'
        }
      }

      return moment(date).format('MM/DD/YYYY')
    },
    uploadParticipantCSV () {
      let input = document.getElementById('csvFile')
      let that = this

      const upload = (event) => {
        const file = event.target.files[0]
        if (file) {
          // Create a FileReader to read the content of the file
          const reader = new FileReader()

          reader.readAsText(file)

          reader.onload = function (e) {
            that.$emit('import-participants', JSON.parse(that.csvToJson(e.target.result)).map(x => {
              let existingRecord = that.questionnaire.participants.find(y => y.participantId === x.participantId)
              return {
                ...existingRecord,
                'participantId': x[0],
                'firstName': x[1],
                'lastName': x[2],
                'previousTitle': x[3],
                'newTitle': x[4],
                'terminationDate': that.handleDateFormat(x[5]), // moment(x[5]),
                'terminationReason': x[6],
                'salary': x[7],
                'bonus': x[8],
                'otherCompensation': x[9],
                'isDeleted': x[10]
              }
            }).filter(x => x.firstName !== undefined && x.lastName !== undefined))

            // uncheck the reviewed info
            that.questionnaire.jsonReviewedBy.bankInformation = ''
            input.removeEventListener('input', upload)

            // clear file selection
            input.value = ''
          }
        }
      }

      input.addEventListener('input', upload)

      input.click()
    },
    validateInput () {
      let contactsCountIsAboveZero = this.questionnaire.participants.filter(c => !c.isDeleted).length > 0

      let contactsAreValid = true
      let errors = []
      this.errorsForm = []

      this.questionnaire.participants.forEach(participant => {
        errors = [...errors, ...this.validateParticipantRow(this, participant)]
      })

      const getUniqueErrors = () => {
        let uniqueErrors = []
        errors.forEach(error => {
          if (!uniqueErrors.find(e => e.message === error.message)) {
            uniqueErrors.push(error)
          }
        })

        return uniqueErrors
      }

      this.errorsForm = [...getUniqueErrors(), ...this.errorsForm]

      this.isValid = contactsCountIsAboveZero && contactsAreValid
      this.$emit('toggleLock', !(this.errorsForm.length === 0))
      this.hasReviewed = true

      if (this.isValid) {
        this.hasReviewed = this.hasReviewed !== '' ? this.hasReviewed : (this.questionnaire.jsonReviewedBy && this.questionnaire.jsonReviewedBy.bankInformation)
      } else {
        this.hasReviewed = ''
      }
    },
    unDeleted (row) {
      row.isDeleted = 0
      this.questionnaire.participants.forEach(participant => {
        if (participant.participantId === row.participantId) {
          participant.isDeleted = 0
        }
      })
      this.$forceUpdate()
    },
    deleteRow (row) {
      row.isDeleted = 1
      this.questionnaire.participants.forEach(participant => {
        if (participant.participantId === row.participantId) {
          participant.isDeleted = 1
        }
      })
      this.$forceUpdate()
    },
    GetBankInformation () {
      this.input = this.questionnaire
      this.hasReviewed = this.questionnaire.jsonReviewedBy && this.questionnaire.jsonReviewedBy.bankInformation
      this.isLoading = false
    },
    resetContactTable () {
      this.isLoading = true
      this.$emit('reset')
    },
    addContactRow () {
      this.questionnaire.participants.push({
        name: '',
        previousTitle: '',
        newTitle: '',
        terminationDate: '',
        terminationReason: '',
        salary: '',
        bonus: '',
        otherCompensation: '',
        firstName: '',
        lastName: '',
        isDeleted: 0
      })
    },
    removeRow (array, rowIndex) {
      let deletedParticipants = this.questionnaire.participants.filter(x => parseInt(x.isDeleted) === 1)
      let nonDeletedParticipants = [...this.questionnaire.participants.filter(x => parseInt(x.isDeleted) === 0)]
      this.questionnaire.participants = [...deletedParticipants, ...nonDeletedParticipants.filter((_, index) => rowIndex.rowIndex !== index)]
      this.$forceUpdate()
    }
  }
}
</script>

  <style scoped>

  .columns {
    width: 100% !important;
    margin: 0px !important;
  }

  .column {
    padding: 0.5rem !important;
    margin: 0rem !important;
  }

  .show-deleted {
    position: absolute;
    right: 0;
    top: calc(50% - 1rem);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .show-deleted button:not(.button),
  .show-deleted a:not(.button) {
    background: transparent;
    border: 0px;
    cursor: pointer;
  }

  .show-deleted button:not(.button):hover,
  .show-deleted a:not(.button):hover {
    text-decoration: underline;
  }

  .table {
    background-color: transparent;
  }
  </style>
