<template>
  <div class="annual-questionnaire-form ">
    <div class="columns" style="width: 100%; padding: 0px!important;">
      <div class="column" style="width: 100%; padding: 0px!important;">
        <div
          v-if="false"
          class="subheading"
          style="
            padding: 0px 0px 7px 0px;
            margin-bottom: 1.5rem;
            border-bottom: solid 1px lightgray;
          "
        >
          <b>Bank Contact Information</b>
        </div>
        <div class="columns" v-if="false" style="width: 100%; padding:  0px 0px 7px 0px!important; gap: .5rem;">
          <div
            class="column flex-container flex-full"
            style="
              flex-wrap: wrap;
              display: flex !important;
              flex-direction: row!important;
              gap: 0.5rem !important;
              padding: 0px;
            "
          >
            <div class="field" style="width: 100%">
              <label class="label text-left is-size-7">
                Legal Address
                <span class="has-text-warning" style="padding-left: 3px">*</span>
              </label>
              <div class="control-center" style="width: 100%">
                <input disabled="disabled"
                  style="width: 100%"
                  v-model="input.clientAddress"
                  name="legal address"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('legal address')"
                  class="help is-danger"
                  >{{ errors.first("legal address") }}</span
                >
              </div>
            </div>
            <div class="field" style="max-width: calc(50%) !important">
              <label class="label text-left is-size-7">
                City
                <span class="has-text-warning" style="padding-left: 3px">*</span>
              </label>
              <div class="control-center" style="width: 100%">
                <input disabled="disabled"
                  style="width: 100%"
                  v-model="input.clientAddressCity"
                  name="legal address"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('legal address')"
                  class="help is-danger"
                  >{{ errors.first("legal address") }}</span
                >
              </div>
            </div>
            <div class="field" style="max-width: calc(15%) !important">
              <label class="label text-left is-size-7">
                ST
                <span class="has-text-warning" style="padding-left: 3px">*</span>
              </label>
              <div class="control-center" style="width: 100%">
                <input disabled="disabled"
                  style="width: 100%"
                  v-model="input.clientAddressState"
                  name="legal address"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('legal address')"
                  class="help is-danger"
                  >{{ errors.first("legal address") }}</span
                >
              </div>
            </div>
            <div class="field" style="max-width: calc(30%) !important">
              <label class="label text-left is-size-7">
                Postal Code
                <span class="has-text-warning" style="padding-left: 3px">*</span>
              </label>
              <div class="control-center" style="width: 100%">
                <input disabled="disabled"
                  style="width: 100%"
                  v-model="input.clientAddressPostal"
                  name="legal address"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('legal address')"
                  class="help is-danger"
                  >{{ errors.first("legal address") }}</span
                >
              </div>
            </div>
          </div>
          <div
            class="column flex-container flex-full"
            style="
              flex-wrap: wrap;
              flex-direction: row!important;
              display: flex;
              gap: 0.5rem !important;
              padding: 0px;
            "
          >
            <div class="field" style="width: 100%">
              <label class="label text-left is-size-7">
                Mailing Address
                <span class="has-text-warning" style="padding-left: 3px">*</span>
              </label>
              <div class="control-center" style="width: 100%">
                <input disabled="disabled"
                  style="width: 100%"
                  v-model="input.clientMailingAddress"
                  name="legal address"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('legal address')"
                  class="help is-danger"
                  >{{ errors.first("legal address") }}</span
                >
              </div>
            </div>
            <div class="field" style="max-width: calc(50%) !important">
              <label class="label text-left is-size-7">
                City
                <span class="has-text-warning" style="padding-left: 3px">*</span>
              </label>
              <div class="control-center" style="width: 100%">
                <input disabled="disabled"
                  style="width: 100%"
                  v-model="input.clientMailingAddressCity"
                  name="legal address"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('legal address')"
                  class="help is-danger"
                  >{{ errors.first("legal address") }}</span
                >
              </div>
            </div>
            <div class="field" style="max-width: calc(15%) !important">
              <label class="label text-left is-size-7">
                ST
                <span class="has-text-warning" style="padding-left: 3px">*</span>
              </label>
              <div class="control-center" style="width: 100%">
                <input disabled="disabled"
                  style="width: 100%"
                  v-model="input.clientMailingAddressState"
                  name="legal address"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('legal address')"
                  class="help is-danger"
                  >{{ errors.first("legal address") }}</span
                >
              </div>
            </div>
            <div class="field" style="max-width: calc(30%) !important">
              <label class="label text-left is-size-7">
                Postal Code
                <span class="has-text-warning" style="padding-left: 3px">*</span>
              </label>
              <div class="control-center" style="width: 100%">
                <input disabled="disabled"
                  style="width: 100%"
                  v-model="input.clientMailingAddressPostal"
                  name="legal address"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('legal address')"
                  class="help is-danger"
                  >{{ errors.first("legal address") }}</span
                >
              </div>
            </div>
          </div>
          <div
            class="column flex-container flex-full"
            style="
              flex-wrap: wrap;
              gap: 0.5rem !important;
              padding: 0px;
            "
          >
            <div
              class="field"
              style="width: 100%; padding: 0 !important; margin: 0px !important"
            >
              <label class="label text-left is-size-7">
                Main Phone
                <span class="has-text-warning" style="padding-left: 3px">*</span>
              </label>
              <div class="control-center" style="width: 100%; ">
                <input disabled="disabled"
                style="width: 100%; "
                  v-model="input.clientPhone"
                  @keydown="forceFormat"
                  @keyup="formatPhoneInput"
                  name="business phone"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('business phone')"
                  class="help is-danger"
                  >{{ errors.first("business phone") }}</span
                >
              </div>
            </div>
            <div class="field" style="width: 100%; margin-top: .6rem">
              <label class="label text-left is-size-7">
                Main Fax
                <span class="has-text-warning" style="padding-left: 3px">*</span>
              </label>
              <div class="control-center" style="width: 100%; ">
                <input disabled="disabled"
                style="width: 100%; "
                  v-model="input.clientFax"
                  @keydown="forceFormat"
                  @keyup="formatPhoneInput"
                  name="fax number"
                  v-validate="'required'"
                />
                <span v-show="errors.has('fax number')" class="help is-danger">{{
                  errors.first("fax number")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns" style="width: 100%; padding: 0px !important">
    <div
      v-if="!isLoading && false"
      class="column"
      style="width: 100%; padding: 0px !important"
    >
      <div
        class="subheading"
        style="
          padding: 0px 0px 7px 0px;
          margin-bottom: .5rem;
          border-bottom: solid 1px lightgray;
          position: relative;
        "
      >
        <b>Contact Information</b>
      </div>
      <div
        class="columns"
        style="width: 100%; padding: 0px 0px 7px 0px !important; gap: 0.5rem"
      >
        <div class="column">
          <div class="table">

            <DataTable
              :header-fields="contactFields"
              :tableData="questionnaire.participants"
              :is-loading="isLoading"
              :css="datatableCss"
              :disabled="true"
              :disableAddRow="isViewOnly"
              :limit="100"
              not-found-msg="No contacts found"
            >
            </DataTable>
            <br />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
    </div>
    <div class="columns" style="width: 100%; padding: 0px !important;">
      <div
        v-if="!isLoading"
        class="column"
        style="width: 100%; padding: 0px !important"
      >
        <div
          class="subheading"
          style="
            padding: 0px 0px 7px 0px;
            margin-bottom: .5rem;
            border-bottom: solid 1px lightgray;
            position: relative;
          "
        >
          <b>Participant Information</b>
        </div>
        <div
          class="columns"
          style="width: 100%; padding: 0px 0px 7px 0px !important; gap: 0.5rem"
        >
          <div class="column">
            <div class="table">
              <DataTable
                :tableDataHash="hash(questionnaire.participants)"
                :disabled="true"
                :header-fields="participantFields"
                :tableData="questionnaire.participants"
                :disableAddRow="true"
                :is-loading="isLoading"
                :css="datatableCss"
                :limit="100"
                not-found-msg="No Participants found"
                @validate-data="validateInput"
              >
              </DataTable>

              <span v-for="error in errorsForm" :key="error.message">
                <p style="color: red">{{ error.message }}</p>
              </span>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <Loading />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div style="width: 100%; margin-bottom: 3rem;">
          <div style="display: flex; justify-content: flex-start; align-items: center;">
          <input
            type="checkbox"
            name="confirmed"
            v-model="hasReviewed"
            :disabled="errorsForm.length > 0"
            style="margin-right: 0.75rem"
          />
          <label for="confirmed" @click="hasReviewed = !hasReviewed" :disabled="errorsForm.length > 0"
            >I have reviewed the above information, and I certify that the above facts are true to the best of my knowledge and belief.</label
          >
        </div>
          <p v-if="questionnaire.jsonReviewedBy && questionnaire.jsonReviewedBy.review" style="color: red;">
            {{ questionnaire.jsonReviewedBy.review }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '../../../templates/DataTable'
// import moment from 'moment'
import crypto from 'crypto'

export default {
  name: 'BankInformationSlide',
  components: {
    DataTable
  },
  props: {
    questionnaire: {
      type: Object,
      required: true
    }
  },
  watch: {
    hasReviewed: function (val, oldVal) {
      this.$emit('has-reviewed', val)

      if (val === true) {
        this.$emit('toggleLock', false)
      } else {
        this.$emit('toggleLock', true)
      }
    },
    'questionnaire.participants': {
      handler: function (val, oldVal) {
        this.GetBankInformation()
        this.hasReviewed = false
      },
      deep: true
    }
  },
  data: function () {
    return {
      errorsForm: [],
      isLoading: false,
      hasReviewed: false,
      interval: null,
      showDeletedContacts: false,
      contactFields: [
        {
          name: 'name',
          label: 'Name',
          width: '25%',
          isEditable: true
        },
        {
          name: 'title',
          label: 'Title',
          width: '25%',
          isEditable: true
        },
        {
          name: 'phone',
          label: 'Direct Phone',
          format: 'phone',
          width: '25%',
          isEditable: true
        },
        {
          name: 'email',
          label: 'Email',
          width: '25%',
          isEditable: true
        },
        '__slot:actions'
      ],
      participantFields: [
        {
          name: 'firstName',
          label: 'First Name',
          width: '11.11111%',
          isEditable: true
        },
        {
          name: 'lastName',
          label: 'Last Name',
          width: '11.11111%',
          isEditable: true
        },
        {
          name: 'previousTitle',
          label: 'Previous Title',
          width: '11.11111%',
          isEditable: true
        },
        {
          name: 'newTitle',
          label: 'New Title',
          width: '11.11111%',
          isEditable: true
        },
        {
          name: 'terminationDate',
          label: 'Termination Date',
          format: 'date',
          width: '150px',
          isEditable: true
        },
        {
          name: 'terminationReason',
          label: 'Termination Reason',
          width: '11.11111%',
          isEditable: true
        },
        {
          name: 'salary',
          label: 'Salary',
          width: '11.11111%!important',
          isEditable: true
        },
        {
          name: 'bonus',
          label: 'Bonus',
          width: '11.11111%!important',
          isEditable: true
        },
        {
          name: 'otherCompensation',
          label: 'Other Compensation',
          width: '11.11111%!important',
          isEditable: true
        },
        '__slot:actions'
      ],
      isViewOnly: true,
      datatableCss: {
        table: 'table table-bordered table-hover table-striped table-center',
        th: 'header-item',
        thWrapper: 'th-wrapper',
        footer: 'footer'
      },
      input: {
        clientAddress: '',
        clientAddressCity: '',
        clientAddressState: '',
        clientAddressPostal: '',
        clientMailingAddress: '',
        clientMailingAddressCity: '',
        clientMailingAddressState: '',
        clientMailingAddressPostal: '',
        clientPhone: '',
        clientFax: ''
      }
    }
  },
  mounted () {
    this.$emit('rendered', this)

    this.validateInput()

    this.interval = setInterval(() => {
      this.validateInput()
    }, 1000)
    this.GetBankInformation()
  },
  beforeDestroy () {
    clearInterval(this.interval)
    this.interval = null
  },
  computed: {
    hasDeletedContacts () {
      return this.questionnaire.contacts &&
        this.questionnaire.contacts.length > 0
        ? this.questionnaire.contacts.filter((c) => c.isDeleted).length > 0
        : false
    },
    filteredContacts () {
      return this.questionnaire.contacts &&
        this.questionnaire.contacts.length > 0
        ? this.showDeletedContacts
          ? this.questionnaire.contacts
          : this.questionnaire.contacts.filter((c) => !c.isDeleted)
        : []
    },
    hasDeletedParticipants () {
      return this.questionnaire.participants &&
          this.questionnaire.participants.length > 0
        ? this.questionnaire.participants.filter((c) => c.isDeleted).length > 0
        : false
    },
    filteredParticipants () {
      return this.questionnaire.participants &&
          this.questionnaire.participants.length > 0
        ? this.showDeletedContacts
          ? this.questionnaire.participants
          : this.questionnaire.participants.filter((c) => c.isDeleted === undefined || parseInt(c.isDeleted) === 0)
        : []
    }
  },
  methods: {
    encode (item) {
      return JSON.stringify(item)
    },
    hash (item) {
      return crypto.createHash('sha256').update(JSON.stringify(item)).digest('hex')
    },
    validateInput () {
      let contactsCountIsAboveZero = this.questionnaire.participants.filter(c => !c.isDeleted).length > 0

      let contactsAreValid = true
      let errors = []
      this.errorsForm = []

      for (let participant of this.questionnaire.participants) {
        participant.hasfirstNameerror = false
        participant.haslastNameerror = false
        participant.haspreviousTitleerror = false
        participant.hassalaryerror = false
        participant.hasTerminationDateerror = false
        participant.hasTerminationReasonerror = false

        if (participant.isDeleted) {
          continue
        }

        errors.push(...this.validateParticipantRow(this, participant))
      }

      const getUniqueErrors = () => {
        let uniqueErrors = []
        errors.forEach(error => {
          if (!uniqueErrors.find(e => e.message === error.message)) {
            uniqueErrors.push(error)
          }
        })

        return uniqueErrors
      }

      this.errorsForm = [...getUniqueErrors(), ...this.errorsForm]

      this.isValid = contactsCountIsAboveZero && contactsAreValid

      if (this.isValid) {
        // this.hasReviewed = this.hasReviewed !== '' && this.hasReviewed !== undefined ? this.hasReviewed : (this.questionnaire.jsonReviewedBy && this.questionnaire.jsonReviewedBy.bankInformation)
      } else {
        this.hasReviewed = false
      }
    },
    validateParticipantRow (_, participant) {
      const SALARY_REQUIRED_FOR_UN_TERMINATED = {
        message: 'Salary is required if Termination Date is not provided',
        field: 'salary',
        color: 'red'
      }

      const FIRST_NAME_REQUIRED = {
        message: 'First Name is required',
        field: 'firstname',
        color: 'red'
      }

      const LAST_NAME_REQUIRED = {
        message: 'Last Name is required',
        field: 'lastname',
        color: 'red'
      }

      const errors = []

      const hasFirstNameError = (participant.firstName === '' || participant.firstName === undefined || participant.firstName === null)
      const hasLastNameError = (participant.lastName === '' || participant.lastName === undefined || participant.lastName === null)
      const hasSalaryError = (participant.salary === '' || participant.salary === undefined || participant.salary === null) && (participant.terminationDate === '' || participant.terminationDate === undefined || participant.terminationDate === null)
      // const hasValidDateError = participant.terminationDate && !validDateFormats.some(format => moment(participant.terminationDate, format, true).isValid())
      if (hasFirstNameError) {
        errors.push(FIRST_NAME_REQUIRED)
        participant.hasfirstNameerror = true
      }

      participant.hasfirstNameerror = hasFirstNameError

      if (hasLastNameError) {
        errors.push(LAST_NAME_REQUIRED)
        participant.haslastNameerror = true
      }

      participant.haslastNameerror = hasLastNameError

      if (hasSalaryError) {
        errors.push(SALARY_REQUIRED_FOR_UN_TERMINATED)
        participant.hassalaryerror = true
      }

      participant.hassalaryerror = hasSalaryError

      return errors
    },
    GetBankInformation () {
      this.input = this.questionnaire
      this.hasReviewed = this.questionnaire.jsonReviewedBy && this.questionnaire.jsonReviewedBy.review

      setTimeout(() => {
        window.requestAnimationFrame(() => {
          document.querySelectorAll('input')
            .forEach(input => {
              if (input.type === 'checkbox') return
              input.setAttribute('disabled', true)
              input.addEventListener('click', _ => _.stopPropagation())
            })
          document.querySelectorAll('.k-widget .k-select')
            .forEach(input => input.remove())
        })
      }, 100)
    }
  }
}
</script>
  <style scoped>
  .columns {
    width: 100% !important;
    margin: 0px !important;
  }

  .column {
    padding: 0.5rem !important;
    margin: 0rem !important;
  }

  .show-deleted {
    position: absolute;
    right: 0;
    top: calc(50% - 1rem);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .show-deleted button:not(.button),
  .show-deleted a:not(.button) {
    background: transparent;
    border: 0px;
    cursor: pointer;
  }

  .show-deleted button:not(.button):hover,
  .show-deleted a:not(.button):hover {
    text-decoration: underline;
  }

  .table {
    background-color: transparent !important;
  }
  .annual-questionnaire-form {
    overflow: scroll;
  }

.annual-questionnaire-form input[disabled=true] {
  opacity: .75!important;
  background: #f0f0f0;
}

input[disabled=true] {
  opacity: .75!important;
  background: #f0f0f0;
}

.k-datepicker:hover {
  border-color: transparent;
  color: #424242;
  background-color: transparent;
}

.k-datepicker {
  border-color: transparent;
  color: #424242;
  background-color: transparent;
}
</style>
