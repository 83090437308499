<template>
  <div
    style="
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div style="max-width: 550px">
      <h1 style="text-align: center; font-size: 3rem">Thank You!</h1>
      <p style="text-align: center">
        Thank you for completing the Annual Questionnaire. A copy of your
        response will be added to your account and accessible to authorized
        users.
      </p>
      <p style="text-align: center">
        <br>
        If you need to make any further adjustments to your Annual Questionnaire,&nbsp;
        please email bccclientservices@bcc-usa.com or call 800-781-2099.
      </p>
      <br />
      <p style="text-align: center">
        You will be redirected to your dashboard in
        {{ secondsUntilRedirect }} seconds.
      </p>
      <p style="text-align:  center; margin-top: 3rem;">
        Click <a href="/">here</a> to proceed.
      </p>
    </div>
  </div>
</template>

<script>
import { bus } from '@/main'

export default {
  name: 'ThankYouSlide',
  props: {
    questionnaire: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      secondsUntilRedirect: 60,
      interval: null
    }
  },
  mounted () {
    const callback = () => {
      this.isLoading = false
      this.interval = setInterval(() => {
        this.secondsUntilRedirect--
        if (this.secondsUntilRedirect === 0) {
          bus.$emit('full-screen-toggle', false)
          this.$router.push('/#/dashboard').then(() => {
            window.location.reload()
          })
        }
      }, 1000)
    }

    this.$emit('finish-annual-questionnaire', callback.bind(this))
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>
<style scoped>
.columns {
  width: inherit !important;
  margin: inherit !important;
}

.column {
  padding: inherit !important;
  margin: inherit !important;
}

.show-deleted {
  position: absolute;
  right: 0;
  top: calc(50% - 1rem);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.show-deleted button:not(.button),
.show-deleted a:not(.button) {
  background: transparent;
  border: 0px;
  cursor: pointer;
}

.show-deleted button:not(.button):hover,
.show-deleted a:not(.button):hover {
  text-decoration: underline;
}

.table {
  background-color: transparent !important;
}
.annual-questionnaire-form {
  overflow: scroll;
}

.annual-questionnaire-form input[disabled="true"] {
  opacity: 0.75 !important;
  background: #f0f0f0;
}

input[disabled="true"] {
  opacity: 0.75 !important;
  background: #f0f0f0;
}

.k-datepicker:hover {
  border-color: inherit !important;
  color: inherit !important;
  background-color: transparent !important;
}

.k-datepicker {
  border-color: inherit !important;
  color: inherit !important;
  background-color: transparent !important;
}

.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
</style>
